import React from "react";
import a1 from "../assets/RBSM- DSLR Images/IMG_2707.jpg";
import a2 from "../assets/RBSM- DSLR Images/IMG_2708.jpg";
import a4 from "../assets/RBSM- DSLR Images/IMG_2710.jpg";

import a10 from "../assets/RBSM- DSLR Images/IMG_2716.jpg";
import a11 from "../assets/RBSM- DSLR Images/IMG_2718.jpg";
import a12 from "../assets/RBSM- DSLR Images/IMG_2719.jpg";

import a18 from "../assets/RBSM- DSLR Images/IMG_2725.jpg";
import a19 from "../assets/RBSM- DSLR Images/IMG_2726.jpg";
import a20 from "../assets/RBSM- DSLR Images/IMG_2727.jpg";
import a21 from "../assets/RBSM- DSLR Images/IMG_2728.jpg";

import a29 from "../assets/RBSM- DSLR Images/IMG_2736.jpg";
import a30 from "../assets/RBSM- DSLR Images/IMG_2737.jpg";

import a33 from "../assets/RBSM- DSLR Images/IMG_2740.jpg";
import a34 from "../assets/RBSM- DSLR Images/IMG_2741.jpg";
import a35 from "../assets/RBSM- DSLR Images/IMG_2742.jpg";

import a40 from "../assets/RBSM- DSLR Images/IMG_2747.jpg";
import a41 from "../assets/RBSM- DSLR Images/IMG_2748.jpg";
import a42 from "../assets/RBSM- DSLR Images/IMG_2749.jpg";
import a43 from "../assets/RBSM- DSLR Images/IMG_2750.jpg";
import a44 from "../assets/RBSM- DSLR Images/IMG_2751.jpg";
import a45 from "../assets/RBSM- DSLR Images/IMG_2752.jpg";

import a46 from "../assets/RBSM- DSLR Images/IMG_2753.jpg";
import a47 from "../assets/RBSM- DSLR Images/IMG_2754.jpg";
import a48 from "../assets/RBSM- DSLR Images/IMG_2755.jpg";
import a49 from "../assets/RBSM- DSLR Images/IMG_2756.jpg";
import a50 from "../assets/RBSM- DSLR Images/IMG_2757.jpg";
import a51 from "../assets/RBSM- DSLR Images/IMG_2758.jpg";
import a55 from "../assets/RBSM- DSLR Images/IMG_2762.jpg";
import a56 from "../assets/RBSM- DSLR Images/IMG_2763.jpg";
import a57 from "../assets/RBSM- DSLR Images/IMG_2764.jpg";
import a58 from "../assets/RBSM- DSLR Images/IMG_2765.jpg";
import a59 from "../assets/RBSM- DSLR Images/IMG_2766.jpg";

import a62 from "../assets/RBSM- DSLR Images/IMG_2771.jpg";
import a63 from "../assets/RBSM- DSLR Images/IMG_2772.jpg";
import a64 from "../assets/RBSM- DSLR Images/IMG_2773.jpg";
import a65 from "../assets/RBSM- DSLR Images/IMG_2784.jpg";

import a69 from "../assets/RBSM- DSLR Images/IMG_2789.jpg";
import a70 from "../assets/RBSM- DSLR Images/IMG_2790.jpg";
import a71 from "../assets/RBSM- DSLR Images/IMG_2791.jpg";
import a72 from "../assets/RBSM- DSLR Images/IMG_2793.jpg";
import a73 from "../assets/RBSM- DSLR Images/IMG_2794.jpg";
import a74 from "../assets/RBSM- DSLR Images/IMG_2795.jpg";
import a75 from "../assets/RBSM- DSLR Images/IMG_2796.jpg";


import a84 from "../assets/RBSM- DSLR Images/IMG_2806.jpg";
import a85 from "../assets/RBSM- DSLR Images/IMG_2807.jpg";
import a86 from "../assets/RBSM- DSLR Images/IMG_2808.jpg";
import a87 from "../assets/RBSM- DSLR Images/IMG_2809.jpg";
import a88 from "../assets/RBSM- DSLR Images/IMG_2810.jpg";
import a89 from "../assets/RBSM- DSLR Images/IMG_2811.jpg";
import a90 from "../assets/RBSM- DSLR Images/IMG_2812.jpg";

import a94 from "../assets/RBSM- DSLR Images/IMG_2818.jpg";
import a96 from "../assets/RBSM- DSLR Images/IMG_2820.jpg";
import a97 from "../assets/RBSM- DSLR Images/IMG_2821.jpg";
import a98 from "../assets/RBSM- DSLR Images/IMG_2822.jpg";
import a99 from "../assets/RBSM- DSLR Images/IMG_2823.jpg";
import Footer from "./Footer";
import Header from "./Header";

const images = [
  { src: a47 },
  { src: a59 },
  { src: a62 },
  { src: a63 },


  { src: a64 },
  { src: a65 },
  { src: a70 },
  { src: a71 },


  { src: a72 },
  { src: a73 },
  { src: a56 },
  { src: a75 },

  { src: a84 },
  { src: a85 },
  { src: a86 },
  { src: a87 },

  { src: a88 },
  { src: a89 },
  { src: a90 },
  { src: a94 },

  { src: a96 },
  { src: a97 },
  { src: a98 },
  { src: a99 },
  
  { src: a1 },
  { src: a2 },
  { src: a4 },
  { src: a11 },

  { src: a10 },
  { src: a29 },
  { src: a35 },
  { src: a42 },

  { src: a18 },
  { src: a20 },
  { src: a21 },
  { src: a30 },

  { src: a41 },
  { src: a33 },
  { src: a34 },
  { src: a48 },


  { src: a40 },
  { src: a43 },
  { src: a19 },
  { src: a12 },

  { src: a69 },
  { src: a49 },
  { src: a50 },
  { src: a69 },


  { src: a51 },
  { src: a44 },
  { src: a45 },
  { src: a46 },

  { src: a58 },
  { src: a55 },
  { src: a74 },
  { src: a57 },



];

const Sessions_Rbsm = () => {
  return (
    <div>
      <Header />
      <div className="container-fluid gallery-container">
        <div className="row mt-4">
          {images.map((image, index) => (
            <div className="col-6 col-md-4 col-lg-3" key={index}>
              <div className="card border-0">
                  <img
                    className="img-fluid gallery-img"
                    src={image.src}
                    alt={image.title}
                  />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Sessions_Rbsm;
