import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import b1 from "../assets/love quotes.jpg";
import a1 from "../assets/about-1.jpg";
import a3 from "../assets/about-3.jpg";
import v1 from "../assets/Intro-1.mp4";
import v2 from "../assets/Candle flame -final.mp4";
import v3 from "../assets/The Fight Within.mp4";
import v4 from "../assets/akashvani interview.mp4";

const Gallery = () => {
  return (
    <div>
      <Header />
      <div class="container-fluid firstContainer">
        <div class="row">
          <div class="card col-lg-3 col-md-6 col-sm-12 gallery-card border-0">
            <img
              class="img-fluid gallery-style card-img-top"
              src={a3}
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title mt-3">IMAGES</h5>
              <a href="/galleryimage" class=" btn btn-outline-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div class="card col-lg-3 col-md-6 col-sm-12 gallery-card border-0">
            <video src={v1} class="card-img-top"></video>
            <div class="card-body">
              <h5 class="card-title mt-3">VIDEOS</h5>
              <a href="/galleryvideos" class=" btn btn-outline-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div class="card col-lg-3 col-md-6 col-sm-12 gallery-card border-0">
            <video src={v2} class="card-img-top"></video>
            <div class="card-body">
              <h5 class="card-title mt-3">POETRY</h5>
              <a href="/gallerypoetry" class=" btn btn-outline-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div class="card col-lg-3 col-md-6 col-sm-12 gallery-card border-0">
            <video src={v3} class="card-img-top"></video>
            <div class="card-body">
              <h5 class="card-title mt-3">MOTIVATIONAL VIDEOS</h5>
              <a href="/gallerymotivation" class=" btn btn-outline-primary ">
                Full Album
              </a>
            </div>
          </div>
          <div class="card col-lg-3 col-md-6 col-sm-12 gallery-card border-0">
            <video src={v4} class="card-img-top"></video>
            <div class="card-body">
              <h5 class="card-title mt-3">INTERVIEW VIDEOS</h5>
              <a
                href="/galleryinterviewvideos"
                class=" btn btn-outline-primary "
              >
                Full Album
              </a>
            </div>
          </div>
          <div class="card col-lg-3 col-md-6 col-sm-12 gallery-card border-0">
            <img
              class="img-fluid gallery-style card-img-top"
              src={b1}
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title mt-3">LOVE QUOTES</h5>
              <a
                target="_blank"
                href="https://loveinquotes.com/tag/arun-prabhu-aradhya-arun-love-quotes/"
                class=" btn btn-outline-primary "
              >
                Full Album
              </a>
            </div>
          </div>
          <div class="card col-lg-3 col-md-6 col-sm-12 gallery-card border-0">
            <img
              class="img-fluid gallery-style card-img-top"
              src={a1}
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title mt-3">INTERVIEWS</h5>
              <a href="/galleryinterview" class=" btn btn-outline-primary ">
                Full Album
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
